import(/* webpackMode: "eager", webpackExports: ["default"] */ "/vercel/path0/apps/emser/app/[locale]/footer.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/vercel/path0/apps/emser/app/[locale]/header/index.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/vercel/path0/apps/emser/lib/ui/components/faqs.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/vercel/path0/apps/emser/lib/ui/components/featured-products.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/vercel/path0/apps/emser/lib/ui/components/store-locator.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["__esModule","default"] */ "/vercel/path0/node_modules/.pnpm/@mui+icons-material@5.16.7_@mui+material@5.16.7_@emotion+react@11.13.3_@types+react@18.3.4_re_gt46poi7g52yoaztgec5n6vhwi/node_modules/@mui/icons-material/NavigateNext.js");
;
import(/* webpackMode: "eager" */ "/vercel/path0/node_modules/.pnpm/@mui+material@5.16.7_@emotion+react@11.13.3_@types+react@18.3.4_react@18.3.1__@emotion+styled_u54plt3h22lcxai37fz66nmgt4/node_modules/@mui/material/Breadcrumbs/index.js");
;
import(/* webpackMode: "eager" */ "/vercel/path0/node_modules/.pnpm/@mui+material@5.16.7_@emotion+react@11.13.3_@types+react@18.3.4_react@18.3.1__@emotion+styled_u54plt3h22lcxai37fz66nmgt4/node_modules/@mui/material/Button/index.js");
;
import(/* webpackMode: "eager" */ "/vercel/path0/node_modules/.pnpm/@sanity+preview-kit@5.0.20_@sanity+client@6.22.4_react@18.3.1/node_modules/@sanity/preview-kit/dist/live-query/LiveQueryClientComponent.js");
;
import(/* webpackMode: "eager" */ "/vercel/path0/node_modules/.pnpm/next-sanity@7.1.4_@sanity+client@6.22.4_@sanity+icons@3.4.0_react@18.3.1__@sanity+types@3.55._gop6zduhvb2pnvbwd4xtoglpmm/node_modules/next-sanity/dist/visual-editing/VisualEditing.js");
;
import(/* webpackMode: "eager" */ "/vercel/path0/node_modules/.pnpm/next@14.2.6_@babel+core@7.26.0_@opentelemetry+api@1.9.0_@playwright+test@1.48.2_babel-plugin-_arttaek6pqv4p7xbyx7fqzpfcu/node_modules/next/dist/client/image-component.js");
;
import(/* webpackMode: "eager" */ "/vercel/path0/node_modules/.pnpm/next@14.2.6_@babel+core@7.26.0_@opentelemetry+api@1.9.0_@playwright+test@1.48.2_babel-plugin-_arttaek6pqv4p7xbyx7fqzpfcu/node_modules/next/dist/client/link.js");
